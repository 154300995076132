import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import "./../css/widget/SearchForm.css";
class BookSearch extends Component {
    constructor(props) {
        super(props); 
        this.state = {
            placeholder:props.placeholder,
            handleSubmit:props.handleSubmit,
            formClass:props.formClass,
            searchQuery: '',
        };   
    } 
    
    componentDidMount() {
     
        document.body.classList.add('detailpageclass');
    }

    componentWillUnmount() {
      
        document.body.classList.remove('detailpageclass');
    }
    static getDerivedStateFromProps(props, state) {
        return {
            placeholder:props.placeholder,
            handleSubmit:props.handleSubmit,
            formClass:props.formClass
        };
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value }, () => {
           
            const searchArray = value ? [value] : [];
            this.props.bookresult(searchArray);
        });
    };

    render() {
        return (
            <>
                <Form action='/search' className={"search-form "+this.state.formClass} onSubmit={(e) => e.preventDefault()}>
                    
                   <Form.Control 
                        type="search" 
                        name="searchQuery" 
                        value={this.state.searchQuery}
                        onChange={this.handleInputChange}
                        className="form-control" 
                        placeholder={this.state.placeholder} 
                    />
                    <Button className="search-btn" type="submit"></Button>
                </Form>
            </>
          );
    }
} 

export default BookSearch;